import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import GmsTola from "../../../components/homeComponents/GmsTola";
import axios from "axios";
import GoToTop from "../../../Scroll/GoToTop";
import { Container } from "../../../components/Container";

// Utility function to check if the URL is a valid YouTube URL
const isValidYouTubeUrl = (url) => {
  const youtubeRegex =
    /^(https?\:\/\/)?(www\.|m\.)?(youtube|youtu|youtube-nocookie)\.(com|be)\/(watch\?v=|(?:e(?:mbed)?\/|v\/|.*[?&]v=))([a-zA-Z0-9_-]{11})$/;
  return youtubeRegex.test(url);
};

// YouTube Embed Component
const YouTubeEmbed = ({ url }) => {
  const videoId = url.match(
    /(?:https?:\/\/(?:www\.|m\.)?(?:youtube\.com\/(?:[^\/\n]+\/\S+|(?:v|e(?:mbed)?)\/|(?:v|e(?:mbed)?)\/|(?:.*[?&]v=))|youtu\.be\/))([\w-]+)(?:[?&=].*)?/
  );
  return videoId ? (
    <iframe
      width="500"
      height="281"
      src={`https://www.youtube.com/embed/${videoId[1]}`}
      frameBorder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    ></iframe>
  ) : null;
};

const ReadMoreNews = () => {
  const { id } = useParams();
  const [news, setNews] = useState(null);

  useEffect(() => {
    console.log("Fetching data for ID:", id);
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/getnews/${id}`)
      .then((res) => {
        if (res.data && res.data.data) {
          setNews(res.data.data);
        } else {
          console.error("Unexpected response format:", res.data);
        }
      })
      .catch((err) => {
        console.error("API Error:", err);
      });
  }, [id]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [news]);

  const formatDateTime = (dateTime) => {
    const date = new Date(dateTime);
    return {
      date: date.toLocaleDateString(),
    };
  };

  return (
    <Container>
      <div>
        <GmsTola />
        <div className="px-[10px] my-[20px] font-poppins">
          <h1 className="border-b-[1px]">News</h1>
          {news ? (
            <ul key={news.id} className="list-disc my-[20px]">
              <p> Date: {formatDateTime(news.date).date}</p>
              <img
                className="mx-auto my-[20px] md:w-auto md:h-auto h-[300px]"
                src={news.imageUrl}
                alt="NoticeImage"
              />
              <p className="mx-auto ">{news.description}</p>
              <div className="flex justify-center items-center">
                <div className="mt-[-12px] w-[600px]">
                  {news.linkUrl && isValidYouTubeUrl(news.linkUrl) ? (
                    <YouTubeEmbed url={news.linkUrl} />
                  ) : (
                    news.linkUrl && (
                      <a
                        href={news.linkUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500"
                      >
                        {news.linkUrl}
                      </a>
                    )
                  )}
                </div>
              </div>
            </ul>
          ) : (
            <p>Loading...</p>
          )}
        </div>
        <GoToTop />
      </div>
    </Container>
  );
};

export default ReadMoreNews;
