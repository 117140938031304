// import React, { useEffect, useState } from "react";
// import GmsTola from "../../../components/homeComponents/GmsTola";

// import ScrollTop from "../../../Scroll/ScrollTop";
// import GoToTop from "../../../Scroll/GoToTop";

// import axios from "axios";
// import { Container } from "../../../components/Container";
// import { FaRegCalendarAlt } from "react-icons/fa";

// const apiUrl = process.env.REACT_APP_BACKEND_URL;
// const PastCommittee = () => {
//   const [data, setData] = useState([]);

//   useEffect(() => {
//     axios
//       .get(`${apiUrl}/api/v1/getpostcommittee`)
//       .then((res) => {
//         // Sort the data in descending order by date
//         const sortedData = res.data.data.sort(
//           (a, b) => new Date(b.date) - new Date(a.date)
//         );
//         setData(sortedData);
//       })
//       .catch((err) => {
//         console.log(err);
//       });
//   }, []);

//   const formatDateTime = (dateTime) => {
//     const date = new Date(dateTime);
//     return {
//       date: date.toLocaleDateString(),
//     };
//   };

//   return (
//     <Container>
//       <div className="font-poppins ">
//         <ScrollTop />
//         <GmsTola />
//         <div className="mx-[10px]">
//           <div className=" border-b-[1px] border-blue-gray-200 mt-[10px] mb-[40px] ">
//             <h1 className="text-[35px] md:text-[25px] ">Past Committee</h1>
//           </div>

//           <div>
//             {/* --------Past Committee members---- */}

//             <div className="grid grid-cols-5 md:grid-cols-3 text-center justify-center items-center h-auto  border-b-[2px] mb-[30px] pb-[20px] sm:hidden pt-[10px]">
//               {data.map((member) => {
//                 const { date } = formatDateTime(member.date);
//                 return (
//                   <div key={member.id} className="flex flex-col items-center">
//                     {/* Image Container */}
//                     <div className="relative w-[120px] h-[100px] flex justify-center">
//                       <div className="h-[60px] w-[120px] bg-blue-gray-400 bottom-0 absolute"></div>
//                       <img
//                         className="absolute h-[120px] bottom-0"
//                         src={member.imageUrl}
//                         alt={member.name}
//                       />
//                     </div>
//                     {/* Name and Position */}
//                     <div className="mt-[10px] font-medium space-y-[2px] h-auto">
//                       <p className="mb-[-4px] flex items-center justify-center">
//                         <FaRegCalendarAlt className="mr-[10px]" />
//                         {date}
//                       </p>
//                       <p className="h-auto">{member.name}</p>
//                       <p className="pb-[45px]">{member.position}</p>
//                     </div>
//                   </div>
//                 );
//               })}
//             </div>
//             {/* ----small size--- */}
//             <div className="sm:flex hidden pt-[10px]">
//               <div className=" grid grid-cols-2  text-center justify-center items-center h-auto  border-b-[2px] mb-[30px] pb-[20px] space-x-1">
//                 {data.map((member) => (
//                   <div key={member.id} className="flex flex-col items-center">
//                     {/* Image Container */}
//                     <div className="relative w-[120px] h-[100px] flex justify-center">
//                       <div className="h-[60px] w-[120px] bg-blue-gray-400 bottom-0 absolute"></div>
//                       <img
//                         className="absolute h-[120px] bottom-0"
//                         src={member.imageUrl}
//                         alt={member.name}
//                       />
//                     </div>
//                     {/* Name and Position */}
//                     <div className="mt-[10px] font-medium space-y-[-15px] h-auto ">
//                       <p>{member.name}</p>
//                       <p className="pb-[30px]">{member.position}</p>
//                     </div>
//                   </div>
//                 ))}
//               </div>
//             </div>
//           </div>
//         </div>
//         <GoToTop />
//       </div>
//     </Container>
//   );
// };

// export default PastCommittee;


import React, { useEffect, useState } from "react";
import GmsTola from "../../../components/homeComponents/GmsTola";

import ScrollTop from "../../../Scroll/ScrollTop";
import GoToTop from "../../../Scroll/GoToTop";

import axios from "axios";
import { Container } from "../../../components/Container";
import { FaRegCalendarAlt } from "react-icons/fa";

const apiUrl = process.env.REACT_APP_BACKEND_URL;

const PastCommittee = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    axios
      .get(`${apiUrl}/api/v1/getpostcommittee`)
      .then((res) => {
        // Sort the data in descending order by date
        const sortedData = res.data.data.sort(
          (a, b) => new Date(b.date) - new Date(a.date)
        );
        setData(sortedData);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const formatDateTime = (dateTime) => {
    const date = new Date(dateTime);
    return date.toLocaleDateString();
  };

  // Grouping data by date
  const groupByDate = (data) => {
    return data.reduce((acc, member) => {
      const date = formatDateTime(member.date);
      acc[date] = acc[date] || [];
      acc[date].push(member);
      return acc;
    }, {});
  };

  const groupedData = groupByDate(data);

  return (
    <Container>
      <div className="font-poppins ">
        <ScrollTop />
        <GmsTola />
        <div className="mx-[10px]">
          <div className="border-b-[1px] border-blue-gray-200 mt-[10px] mb-[40px]">
            <h1 className="text-[35px] md:text-[25px]">Past Committee</h1>
          </div>

          <div>
            {/* Render the grouped data */}
            {Object.entries(groupedData).map(([date, members]) => (
              <div
                key={date}
                className="mb-[30px] pb-[20px] border-b-[2px] border-blue-gray-200"
              >
                {/* Date Header */}
                <div className="flex items-center justify-start mb-[10px] text-[18px] font-semibold">
                  <FaRegCalendarAlt className="mr-[8px]" />
                  {date}
                </div>

                {/* Members - desktop view */}
                <div className="grid sm:hidden  grid-cols-4 md:grid-cols-3 gap-4 pt-[20px]">
                  {members.map((member) => (
                    <div
                      key={member.id}
                      className="flex flex-col items-center text-center"
                    >
                      {/* Image Container */}
                      <div className="relative w-[120px] h-[100px] flex justify-center">
                        <div className="h-[60px] w-[120px] bg-blue-gray-400 bottom-0 absolute"></div>
                        <img
                          className="absolute h-[120px] bottom-0"
                          src={member.imageUrl}
                          alt={member.name}
                        />
                      </div>
                      {/* Name and Position */}
                      <div className="mt-[10px] font-medium space-y-[-12px] h-auto">
                        <p className="">{member.name}</p>
                        <p className="text-sm text-black">{member.position}</p>
                      </div>
                    </div>
                  ))}
                </div>

                {/* Members - mobile view */}
                <div className="sm:grid hidden sm:grid-cols-2 gap-4 mt-[40px]">
                  {members.map((member) => (
                    <div
                      key={member.id}
                      className="flex flex-col items-center text-center"
                    >
                      {/* Image Container */}
                      <div className="relative w-[100px] h-[80px] flex justify-center">
                        <div className="h-[50px] w-[100px] bg-blue-gray-400 bottom-0 absolute"></div>
                        <img
                          className="absolute h-[100px] bottom-0"
                          src={member.imageUrl}
                          alt={member.name}
                        />
                      </div>
                      {/* Name and Position */}
                      <div className="mt-[10px] font-medium space-y-[-12px] h-auto">
                        <p>{member.name}</p>
                        <p className="text-xs text-black">{member.position}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
        <GoToTop />
      </div>
    </Container>
  );
};

export default PastCommittee;
