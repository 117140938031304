import React, { useEffect, useState } from "react";
import GmsTola from "../../../components/homeComponents/GmsTola";
import ScrollTop from "../../../Scroll/ScrollTop";
import GoToTop from "../../../Scroll/GoToTop";
import axios from "axios";
import { Container } from "../../../components/Container";
const apiUrl = process.env.REACT_APP_BACKEND_URL;

const ExecutiveC = () => {
  const [president, setPresident] = useState([]);
  const [vicePresident, setVicePresident] = useState([]);
  const [disMember, setDisMember] = useState([]);
  const [imPastPresident, setImPastPresident] = useState([]);
  const [members, setMembers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/api/v1/getexecutivecommittee`
        );
        const { data } = response.data;

        // Assuming data is an array of members with a category field
        setPresident(data.filter((member) => member.category === "president"));
        setVicePresident(
          data.filter((member) => member.category === "vice-president")
        );
        setImPastPresident(
          data.filter((member) => member.category === "imPastCom")
        );
        setDisMember(
          data.filter((member) => member.category === "destMember")
        );
        setMembers(data.filter((member) => member.category === "member"));
      } catch (err) {
        setError(err.message);
        console.log(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <Container>
      <div className="font-poppins ">
        <ScrollTop />
        <GmsTola />
        <div className="mx-[10px]">
          <div className=" border-b-[1px] border-blue-gray-200 mt-[10px] mb-[40px] ">
            <h1 className="text-[35px] md:text-[25px] ">Executive Committee</h1>
          </div>

          <div className="pt-[20px]">
            {/* President Section */}
            {president.map((member) => (
              <div
                key={member.id}
                className="font-poppins   border-b-[2px] mb-[50px]"
              >
                {/* President Section */}
                <div className="flex items-center justify-center">
                  <div className="py-[10px] pt-[20px]">
                    <div className="relative w-[140%] h-[100px] flex justify-center mb-[50px]">
                      {/* Background Color */}
                      <div className="h-[85px] w-[150px] bg-blue-gray-400 opacity-35 bottom-0 absolute"></div>
                      <img
                        className="absolute h-[150px] w-[140px] bg-red bottom-0"
                        src={member.imageUrl}
                        alt={member.name}
                      />
                      <div className="mt-[100px] font-medium space-y-[-17px] text-center">
                        <p>{member.name}</p>
                        <p>{member.position}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}

  {/* - important past members ------------ */}
  <div className="border-b-[2px] grid grid-cols-2 mb-[40px]">
    {/* ------past President----- */}
            <div className=" text-center justify-center items-center h-auto    ">
              {imPastPresident.map((member) => (
                <div key={member.id} className="flex flex-col items-center">
                  {/* Image Container */}
                  <div className="relative w-[120px] h-[100px] flex justify-center">
                    <div className="h-[60px] w-[120px] bg-blue-gray-400 bottom-0 absolute"></div>
                    <img
                      className="absolute h-[120px] bottom-0"
                      src={member.imageUrl}
                      alt={member.name}
                    />
                  </div>
                  {/* Name and Position */}
                  <div className="mt-[10px] font-medium space-y-[-15px] h-auto">
                    <p className="h-auto">{member.name}</p>
                    <p className="pb-[30px]">{member.position}</p>
                  </div>
                </div>
              ))}
            </div>

{/* ------Distinguish Member------ */}
            <div className=" text-center justify-center items-center h-auto    ">
              {disMember.map((member) => (
                <div key={member.id} className="flex flex-col items-center">
                  {/* Image Container */}
                  <div className="relative w-[120px] h-[100px] flex justify-center">
                    <div className="h-[60px] w-[120px] bg-blue-gray-400 bottom-0 absolute"></div>
                    <img
                      className="absolute h-[120px] bottom-0"
                      src={member.imageUrl}
                      alt={member.name}
                    />
                  </div>
                  {/* Name and Position */}
                  <div className="mt-[10px] font-medium space-y-[-15px] h-auto">
                    <p className="h-auto">{member.name}</p>
                    <p className="pb-[30px]">{member.position}</p>
                  </div>
                </div>
              ))}
            </div>

            </div>

  {/* ----------  vice-president------------ */}
            <div className="grid grid-cols-3 sm:grid-cols-2 text-center justify-center items-center h-auto  border-b-[2px] mb-[30px] pb-[20px] ">
              {vicePresident.map((member) => (
                <div key={member.id} className="flex flex-col items-center">
                  {/* Image Container */}
                  <div className="relative w-[120px] h-[100px] flex justify-center">
                    <div className="h-[60px] w-[120px] bg-blue-gray-400 bottom-0 absolute"></div>
                    <img
                      className="absolute h-[120px] bottom-0"
                      src={member.imageUrl}
                      alt={member.name}
                    />
                  </div>
                  {/* Name and Position */}
                  <div className="mt-[10px] font-medium space-y-[-15px] h-auto">
                    <p className="h-auto">{member.name}</p>
                    <p className="pb-[30px]">{member.position}</p>
                  </div>
                </div>
              ))}
            </div>

            {/* --------All members-------*/}
            <div className="grid grid-cols-4 md:grid-cols-3 text-center justify-center items-center h-auto  border-b-[2px] mb-[30px] pb-[20px] sm:hidden pt-[10px]">
              {members.map((member) => (
                <div key={member.id} className="flex flex-col items-center">
                  {/* Image Container */}
                  <div className="relative w-[120px] h-[100px] flex justify-center">
                    <div className="h-[60px] w-[120px] bg-blue-gray-400 bottom-0 absolute"></div>
                    <img
                      className="absolute h-[120px] bottom-0"
                      src={member.imageUrl}
                      alt={member.name}
                    />
                  </div>
                  {/* Name and Position */}
                  <div className="mt-[10px] font-medium space-y-[-15px] h-auto">
                    <p className="h-auto">{member.name}</p>
                    <p className="pb-[25px]">{member.position}</p>
                  </div>
                </div>
              ))}
            </div>
{/* ----small size--- */}
            <div className="sm:flex hidden pt-[10px]">
              <div className=" grid grid-cols-2  text-center justify-center items-center h-auto  border-b-[2px] mb-[30px] pb-[20px] space-x-1">
                {members.map((member) => (
                  <div key={member.id} className="flex flex-col items-center">
                    {/* Image Container */}
                    <div className="relative w-[120px] h-[100px] flex justify-center">
                      <div className="h-[60px] w-[120px] bg-blue-gray-400 bottom-0 absolute"></div>
                      <img
                        className="absolute h-[120px] bottom-0"
                        src={member.imageUrl}
                        alt={member.name}
                      />
                    </div>
                    {/* Name and Position */}
                    <div className="mt-[10px] font-medium space-y-[-15px] h-auto ">
                      <p>{member.name}</p>
                      <p className="pb-[30px]">{member.position}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>


          </div>
        </div>
      </div>
      <GoToTop />
    </Container>
  );
};

export default ExecutiveC;
