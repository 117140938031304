import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom"; // If you are using React Router

const apiUrl = process.env.REACT_APP_BACKEND_URL;

const MoreImage = () => {
  const [data, setData] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const { id } = useParams(); // Get the gallery ID from the URL parameters

  useEffect(() => {
    if (id) {
      axios
        .get(`${apiUrl}/api/v1/getgallery/${id}`)
        .then((response) => {
          const fetchedData = response.data.data || {}; // Get the gallery data
          setData(fetchedData); // Update the state with fetched gallery data
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [id]); // Re-fetch data when the ID changes

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl); // Set the clicked image URL to open the modal
  };

  const closeModal = () => {
    setSelectedImage(null); // Close the modal
  };

  return (
    <>
      <div className="py-[20px] px-[10px] mx-[10px] mb-[20px] font-poppins my-[10px]">
        <h1 className="text-center text-[#343694] underline mb-[25px]">
          Images
        </h1>
        {data ? (
          <div className="grid gap-4 grid-cols-3 md:grid-cols-1 sm:grid-cols-1 pb-[10px]">
            {/* Iterate through imageUrls and display each image */}
            {data.imageUrls && data.imageUrls.length > 0 ? (
              data.imageUrls.map((url, imgIndex) => (
                <div key={imgIndex} onClick={() => handleImageClick(url)}>
                  <img
                    src={url}
                    alt={`Gallery Image ${imgIndex + 1}`}
                    className="w-full h-48 object-cover rounded-md cursor-pointer"
                  />
                </div>
              ))
            ) : (
              <p className="text-center text-gray-500">No Images Available</p>
            )}
          </div>
        ) : (
          <p className="text-center text-gray-500">Loading gallery...</p>
        )}
      </div>

      {/* Full-screen modal */}
      {selectedImage && (
        <div className="fixed inset-0 bg-black bg-opacity-80 flex justify-center items-center z-50">
          <img
            src={selectedImage}
            alt="Full-screen"
            className="max-w-full max-h-full object-contain"
          />
          <button
            className="absolute top-5 right-5 bg-white text-black px-3 py-2 rounded"
            onClick={closeModal}
          >
            Close
          </button>
        </div>
      )}
    </>
  );
};

export default MoreImage;
